* {
  font-family: 'Source Code Pro', monospace;
}
body {
  color: #007510;  
}
button, input, select, textarea {
  border-color: #007510;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
}

button, input, select, a, textarea {
  color: #007510;
}
button {
  background: none;
  cursor: pointer;
}

ul, ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

hr {
  border-top: 1px solid #007510;
  border-bottom: 0;
}

pre {
  margin: 0;
}
table {
  border-collapse: collapse;
}
table, td, th {
  border: 1px solid #aaaaaa;
}

h4 {
  margin: 20px 0 10px;
}
.App {
  padding: 0 10px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: #ffffff;
  background-color: #007510;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding: 2px 10px;
}

.App-header * {
  color: #ffffff;
}
.App-header a#logo {
  flex-grow: 1;
  text-align: left;
  text-decoration: none;
}
#header-menu > * {
  margin: 0 5px;
}

.App-header button {
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
.App-header button.icon > svg {
  font-size: 14px;
}
.App-header span#username {
  margin-left: 10px;
}
.App-header span#username > button {
  padding: 2px 0 2px 2px;
}
.App-link {
  color: #61dafb;
}

.link-button {
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.public-home {
  text-align: center;
}
h1.public-header {
  font-weight: normal;
}

button.home-cta {
  font-size: 2em;
}

div.objects-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
}
div.objects-container > div {
  border: 1px solid #007510;
  min-height: 100px;
  padding: 0 20px 20px;
}
div#objects-container div hr {
  margin-top: 20px;
}
div#objects-container div.new-object {
  padding: 0;
  min-height: 200px;
}

button.main-action {
  font-size: 1.2em;
  margin-top: 10px;
}
div.api-method {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
div.api-method div.description {
  width: 30%;
}
div.api-method div.code {
  width: 70%;
}

div#custom-code-container {
  margin-top: 20px;
}

form#existing-db > input {
  display: block;
  margin-bottom: 1px;
}

div#pricing-container {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
div#pricing-container > div {
  padding: 0 0 0 30px;
}

div#pricing-container ul {
  list-style-type: circle;
}

div#pricing-container li {
  margin: 10px 0;
}

div.tiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
}

div.tiles > div.text {
  padding: 10px 100px;
  text-align: left;
}

div.tiles > div.image {
  padding: 100px;
  font-size: 120px;
}

div.tiles > div.text ul {
  list-style-type: disc;
}
div.tiles > div.text li {
  margin: 10px 0;
}
div#built-with-less {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
div#built-with-less > div {
  margin: 0 10px;
  padding: 20px;
}
div#built-with-less > div > img {
  max-width: 100%;
  max-height: 100%;
}

@media screen 
  and (orientation: portrait) {
    button.home-cta {
      margin-bottom: 30px;
    }
    div.tiles {
      grid-template-columns: 1fr;
    }
    div.tiles > div.image {
      padding: 10px;
      font-size: 120px;
    }
    div.tiles > div.text {
      padding: 10px 20px 10px 30px;
      text-align: left;
    }
    div#built-with-less {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

div#public-demo img {
  max-width: 100%;
}
form#existing-db input {
  width: 200px;
}

table.existing-objects {
  width: 100%;
}
table.existing-objects td:first-child {
  width: 70%;
}
div#documentation-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
div.documentation-sidebar {
  padding-top: 25px;
}
div.documentation-sidebar ol {
  padding-left: 10px;
}
div.documentation-sidebar > ol {
  position: fixed;
}
div.documentation-sidebar li {
  margin: 2px 0;
}
div.documentation ol {
  list-style-type: disc;
  padding-left: 30px;
}
div.documentation h3 {
  margin-top: 40px;
}
div.documentation code {
  border: 1px solid #e4e4e4;
  display: block;
  margin: 10px 0;
  padding: 5px;
}
table.table-records {
  width: 100%;
}
table.table-records td {
  padding: 0;
}
table.table-records td > div {
  max-height: 100px;
  max-width: 400px;
  overflow: hidden;
}
table.table-records textarea {
  height: 100px;
  width: 98%;
  min-width: 200px;
  border: 0;
  font-family: sans-serif;
  resize: none;
}
div.data-table-paging button.selected-page {
  font-weight: bold;
  background-color: #007510;
  color: white;
}
div#csv-importer {
  margin-bottom: 20px;
}
